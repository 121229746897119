import { yupResolver } from "@hookform/resolvers/yup";
import { ArchiveButton } from "components/ArchiveButton";
import { AssumeContactButton } from "components/AssumeContactButton";
import { CategoriesButton } from "components/CategoriesButton";
import { ContactProfile } from "components/ContactProfile";
import { ManageMacroModal } from "components/ManageMacroModal";
import { MessageForm } from "components/MessageForm";
import { sendMessageValidator } from "components/MessageForm/validation";
import { Messages } from "components/Messages";
import { Quote } from "components/Quote";
import { SendFilesMessageModal } from "components/SendFilesMessageModal";
import { TransferButton } from "components/TransferButton";
import { useAuth } from "hooks/useAuth";
import { useContacts } from "hooks/useContacts";
import { useMessages } from "hooks/useMessages";
import { usePaste } from "hooks/usePaste";
import { useQuery } from "hooks/useQuery";
import { useSocket } from "hooks/useSocket";
import { useCount } from "hooks/utils/useCount";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { FieldValues, FormProvider, useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { api } from "services/api";
import { DropzoneFile } from "types/File";
import { isEmpty } from "utils/Misc";
import { handleRequestError } from "utils/Request";
import { Buttons, ChatBox, Container, Dragging, Header, SendFileInput } from "./styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { TagsButton } from "components/TagsButton";

const validationSchema = sendMessageValidator();

export function Chat() {
  const formMethods = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      message: '',
      annotation: false,
    },
  });

  const history = useHistory();

  const { setValue, control } = formMethods;

  const isAnnotation = useWatch({ name: 'annotation', control });

  const { isSelectingContact, selectedContact, selectContact } = useContacts();

  const { user } = useAuth();

  const admins = ["Henrique Fernandes", "Ivan Vitorassi", "Felipe Francisco"];

  const canSendMessage = (selectedContact?.user?.id === user?.id) || (user != null && admins.includes(user?.name));

  useEffect(() => {
    if (!canSendMessage) {
      setValue('annotation', true, { shouldDirty: true });
    } else {
      setValue('annotation', false, { shouldDirty: true });
    }
  }, [setValue, canSendMessage]);

  const markAsReadTimeout = useRef<NodeJS.Timeout | null>(null);

  const { msg } = useQuery();

  const [isManageMacroModalOpen, setIsManageMacroModalOpen] = useState(false);

  const [isSendFilesModalOpen, setIsSendFilesModalOpen] = useState(false);
  const [files, setFiles] = useState<DropzoneFile[]>([]);

  const { channelPublic } = useSocket();
  const queryClient = useQueryClient();

  function handlePaste(pastedFiles: DropzoneFile[]) {
    if (isAnnotation && pastedFiles.length > 0) return handleAnnotationWithAttachmentError();
    if (files.length > 0) return;

    setFiles(pastedFiles);
    handleOpenSendFilesModal();
  }

  const { clearPastedFiles } = usePaste(handlePaste);

  const { revalidateCount } = useCount();

  const {
    messagesQuery: { query },
    selectedQuotedMessage,
    setIsRefetchingMessages,
  } = useMessages();

  useEffect(() => {
    if (!channelPublic || isEmpty(channelPublic)) return;

    channelPublic.listen('.ContactSentMessage', async (e: any) => {

      if (selectedContact.id !== e.data.contactId) {
        return revalidateCount();
      }

      if (
        selectedContact.user
        && user
        && selectedContact.user.id === user.id
      ) {
        try {
          if (markAsReadTimeout.current) {
            clearTimeout(markAsReadTimeout.current);
          }

          markAsReadTimeout.current = setTimeout(async () => {
            await api.post(`/messages/${selectedContact.id}/mark-all-read`);
            queryClient.invalidateQueries('messages');
            queryClient.invalidateQueries('contacts');
          }, 10000);
        } catch (error) {
          handleRequestError('Ocorreu um erro ao marcar conversa como lida.');
        }
      }

      setIsRefetchingMessages(true);
      await query.refetch();
      setIsRefetchingMessages(false);

      revalidateCount();
    });

  }, [channelPublic, selectedContact, query, queryClient, revalidateCount, user, setIsRefetchingMessages]);

  useEffect(() => {
    if (!channelPublic || isEmpty(channelPublic)) return;

    channelPublic.listen('.AckChanged', async () => {
      setIsRefetchingMessages(true);
      await queryClient.invalidateQueries('messages');
      setIsRefetchingMessages(false);
    });
  }, [channelPublic, queryClient, setIsRefetchingMessages]);

  useEffect(() => {
    if (!channelPublic || isEmpty(channelPublic)) return;

    channelPublic.listen('.ContactUpdateUserCompanyId', (e: any) => {
      if (e.data.contact.id === selectedContact.id) {
        selectContact(e.data.contact);
      }
    });
  }, [channelPublic, selectContact, selectedContact.id]);

  const handleAnnotationWithAttachmentError = useCallback(() => {
    toast.error('Não é possível enviar mensagens com anexo como anotação.');
    clearPastedFiles();
  }, [clearPastedFiles]);

  function handleOpenManageMacroModal() {
    setIsManageMacroModalOpen(true);
  }

  function handleCloseManageMacroModal() {
    setIsManageMacroModalOpen(false);
  }

  function handleOpenSendFilesModal() {
    setIsSendFilesModalOpen(true);
  }

  function handleCloseSendFilesModal() {
    setIsSendFilesModalOpen(false);
    setFiles([]);
    clearPastedFiles();
  }

  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleDropAccepted(files: File[]) {
    if (isAnnotation) return handleAnnotationWithAttachmentError();

    const newFiles = files.map((file: File) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    setFiles(newFiles);
    handleOpenSendFilesModal();

    if (fileInputRef.current) fileInputRef.current.value = '';
  }

  function renderDraggingFilesContent(isDragActive: boolean, isDragReject: boolean) {
    if (!isDragActive) {
      return null;
    }

    if (isDragReject) {
      return (
        <Dragging danger>
          <h3>Arquivo não suportado, muitos arquivos selecionados ou tamanho fora do limite</h3>
        </Dragging>
      );
    }

    return (
      <Dragging>
        <h3>Solte os arquivos aqui</h3>
      </Dragging>
    );
  }

  function handleAssumeContact() {
    setValue('annotation', false);
  }

  function handleAssumeArchivedContact() {
    history.push({
      pathname: '/contatos/meus-contatos',
      search: `contato=${selectedContact.id}`,
    });
    handleAssumeContact();
  }

  function handleSelectFile(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.files) handleDropAccepted(Array.from(e.currentTarget.files));
  }

  function renderHeaderButtons() {
    if (!selectedContact.user && !selectedContact.archived_at) {
      return (
        <>
          <CategoriesButton />
          <TagsButton />
          <ArchiveButton />
          <AssumeContactButton onAssumeContact={handleAssumeContact} />
        </>
      );
    }

    if (selectedContact.user?.id === user?.id) {
      if (!selectedContact.archived_at) {
        return (
          <>
            <TagsButton />
            <ArchiveButton />
            <TransferButton />
          </>
        );
      }

      return null;
    }

    if (selectedContact.archived_at) {
      return <AssumeContactButton onAssumeContact={handleAssumeArchivedContact} />
    }

    return (
      <AssumeContactButton onAssumeContact={handleAssumeContact} />
    );
  }

  return (
    <>
      <Container>
        <Header>
          <ContactProfile
            user={selectedContact}
            isSelectingContact={isSelectingContact}
          />
          <Buttons>
            {renderHeaderButtons()}
          </Buttons>
        </Header>

        <ChatBox id="chat-container">
          <SendFileInput ref={fileInputRef} multiple type="file" id="sendFileMessage" onChange={handleSelectFile} />

          <Dropzone onDropAccepted={handleDropAccepted}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div {...getRootProps()} onClick={(e) => e.stopPropagation()}>
                <input {...getInputProps()} />
                <Messages searchedMsg={typeof msg === 'string' ? msg : undefined} />
                {renderDraggingFilesContent(isDragActive, isDragReject)}
              </div>
            )}
          </Dropzone>
        </ChatBox>

        {!isEmpty(selectedQuotedMessage) && <Quote />}

        <FormProvider {...formMethods}>
          <MessageForm
            key={`${selectedQuotedMessage.id}-${selectedContact.id}`}
            handleOpenManageMacroModal={handleOpenManageMacroModal}
            canSendMessage={canSendMessage}
            isAnnotation={isAnnotation}
          />
        </FormProvider>
      </Container>

      {files && <SendFilesMessageModal
        isOpen={isSendFilesModalOpen}
        onRequestClose={handleCloseSendFilesModal}
        files={files}
      />}

      <ManageMacroModal
        isOpen={isManageMacroModalOpen}
        onRequestClose={handleCloseManageMacroModal}
        canSendMessage={canSendMessage}
        isAnnotation={isAnnotation}
      />
    </>
  );
}
