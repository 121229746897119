import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import { ContentEditable } from 'components/Form/ContentEditable';
import { Buttons } from 'components/MessageForm/styles';
import { SendMessageButton } from 'components/SendMessageButton';
import { useContacts } from 'hooks/useContacts';
import { useMessages } from 'hooks/useMessages';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import { useTheme } from 'styled-components';
import { Form } from 'styles/Form';
import { FormRow } from 'styles/FormRow';
import { InputGroup } from 'styles/InputGroup';
import { ChatMessage } from 'types/ChatMessage';
import { DropzoneFile } from 'types/File';
import { isEmpty } from 'utils/Misc';
import { handleRequestError } from 'utils/Request';
import { v4 as uuidv4 } from 'uuid';
import closeImg from '../../assets/close.svg';
import { Container, ContentWrapper, DocumentIcon, Files, ImageWrapper, MessageForm } from './styles';
import { FileMessageData, SendFilesMessageModalProps } from './types';
import { sendMessageValidator } from './validation';

const validationSchema = sendMessageValidator();

export function SendFilesMessageModal({ isOpen, onRequestClose, files }: SendFilesMessageModalProps) {
  const [selectedFile, setSelectedFile] = useState<DropzoneFile>({} as DropzoneFile);

  const { selectedContact } = useContacts();

  const theme = useTheme();

  const {
    messagesQuery: { query },
    setIsRefetchingMessages,
    selectedQuotedMessage,
    setPreviewMessages,
  } = useMessages();

  useEffect(() => {
    setSelectedFile(files[0]);
  }, [files]);

  const formMethods = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      caption: '',
    },
  });

  const { formState, control, handleSubmit, reset } = formMethods;

  async function handleSendFileMessage({ caption }: FileMessageData) {
    const maxFileSize = 35651584; // 34mb
    const maxFiles = 5;

    const quoted = selectedQuotedMessage;

    if (files.length > 5) {
      return toast.error(`Você selecionou muitos arquivos. Por favor, selecione ${maxFiles} arquivos ou menos.`);
    }

    for await (const [index, file] of files.entries()) {
      if (file.size > maxFileSize) {
        return toast.error(`Arquivo "${file.name}" muito grande. Por favor, selecione um arquivo de até ${maxFileSize / 1048576}Mb!`);
      }

      const formData = new FormData();

      setPreviewMessages(prevMessages => {
        return [
          ...prevMessages,
          {
            id: uuidv4(),
            body: file.preview,
            contact_id: selectedContact.id,
            type: 'image',
            quoted: null,
            caption: caption[index],
            is_forwarded: false,
            time: new Date().toString(),
            from_me: true,
            is_read: true,
            ack: 'SENT',
          } as ChatMessage
        ]
      });

      formData.append('contactId', selectedContact.id);
      formData.append('file', file);
      formData.append('caption', caption[index] ?? '');
      formData.append('quotedMsgId', !isEmpty(quoted) ? quoted.whatsapp_msg_id  : '');

      try {
        await api.post('/messages/send-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (error) {
        handleRequestError(error, 'Erro ao enviar mensagem. Por favor, tente novamente!');
      }
    }

    setIsRefetchingMessages(true);
    await query.refetch();
    setPreviewMessages([]);
    setIsRefetchingMessages(false);

    handleRequestClose();
  }

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  if (!selectedFile) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-image-modal"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-close-image-modal"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <Container>
        <ImageWrapper>
          {selectedFile.type && selectedFile.type.startsWith('image/') ? (
              <img
                src={selectedFile.preview}
                alt={selectedFile.name}
                loading="lazy"
              />
            ) : (
              <>
                <DocumentIcon
                  key={selectedFile.preview}
                  size="300"
                  color={theme.colors.danger}
                />
                <span>{selectedFile.name}</span>
              </>
            )
          }
        </ImageWrapper>

        <ContentWrapper>
          <Files>
            {files.map((file: DropzoneFile) => {
                if (!file.type.startsWith('image/')) {
                  return (
                    <DocumentIcon
                      key={file.preview}
                      size="35"
                      color={theme.colors.danger}
                      onClick={() => setSelectedFile(file)}
                    />
                  )
                }

                return (
                  <img
                    key={file.preview}
                    src={file.preview}
                    alt={file.name}
                    height="35"
                    loading="lazy"
                    onClick={() => setSelectedFile(file)}
                  />
                )
              })}
          </Files>

          <FormProvider {...formMethods}>
            <MessageForm>
              <Form onSubmit={handleSubmit(handleSendFileMessage as SubmitHandler<FieldValues>)}>
                <FormRow>
                  {files.map((file, index) => (
                    <InputGroup
                      key={index}
                      className={ cx({ hidden: selectedFile !== file }) }
                      noSpacing
                      flexItem
                    >
                      <ContentEditable
                        name={`caption[${index}]`}
                        control={control}
                        id="caption"
                        placeholder="Digite sua mensagem..."
                        padding=".8rem 3rem .8rem 1.5rem"
                        onSubmit={handleSubmit(handleSendFileMessage as SubmitHandler<FieldValues>)}
                        disabled={!file.type.startsWith('image/')}
                      />
                    </InputGroup>
                  ))}
                  <InputGroup noSpacing>
                    <Buttons>
                      <SendMessageButton formState={formState} isAnnotation={false} />
                    </Buttons>
                  </InputGroup>
                </FormRow>
              </Form>
            </MessageForm>
          </FormProvider>
        </ContentWrapper>
      </Container>
    </Modal>
  );
}
